<template>
    <ContainerComponent direction="vertical">
        <HeaderComponent class="header-component__white" height="44px">
            <RowComponent justify="center" align="center">
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <TooltipComponent width="auto" placement="bottom-start">
                            <template #tooltip><span>Desktop</span></template>
                            <ButtonComponent
                                :class="{ 'is-active': device === 'desktop' }"
                                type="info"
                                @click="handleDevice('desktop')"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
                                    <path d="M8 21h8M12 17v4" />
                                </svg>
                            </ButtonComponent>
                        </TooltipComponent>
                        <TooltipComponent width="auto" placement="bottom">
                            <template #tooltip><span>Tablet</span></template>
                            <ButtonComponent
                                :class="{ 'is-active': device === 'tablet' }"
                                type="info"
                                @click="handleDevice('tablet')"
                            >
                                <svg
                                    :class="{ 'is-rotated': deviceLandscape }"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <rect x="4" y="2" width="16" height="20" rx="2" ry="2" />
                                    <path d="M12 18h.01" />
                                </svg>
                            </ButtonComponent>
                        </TooltipComponent>
                        <TooltipComponent width="auto" placement="bottom">
                            <template #tooltip><span>Phone</span></template>
                            <ButtonComponent
                                :class="{ 'is-active': device === 'phone' }"
                                type="info"
                                @click="handleDevice('phone')"
                            >
                                <svg
                                    :class="{ 'is-rotated': deviceLandscape }"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        d="M16.286 2H7.714C6.768 2 6 2.895 6 4v16c0 1.105.768 2 1.714 2h8.572c.947 0 1.714-.895 1.714-2V4c0-1.105-.767-2-1.714-2ZM12 18h.01"
                                    />
                                </svg>
                            </ButtonComponent>
                        </TooltipComponent>
                        <template v-if="isMobileDevice">
                            <DividerComponent direction="vertical" />
                            <TooltipComponent width="auto" placement="bottom">
                                <template #tooltip><span>Rotate device</span></template>
                                <ButtonComponent type="info" @click="handleLandscape">
                                    <svg
                                        :class="{ 'is-rotated': deviceLandscape }"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <polyline points="1 4 1 10 7 10" />
                                        <polyline points="23 20 23 14 17 14" />
                                        <path
                                            d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"
                                        />
                                    </svg>
                                </ButtonComponent>
                            </TooltipComponent>
                        </template>
                    </RowComponent>
                    <PaginationComponent :total="4" />
                    <RowComponent justify="end" align="center">
                        <TooltipComponent width="auto" placement="bottom">
                            <template #tooltip><span>Show question numbers</span></template>
                            <ButtonComponent
                                :class="{ 'is-active': useNumbers }"
                                type="info"
                                @click="handleNumbers"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M4 9h16M4 15h16M10 3 8 21M16 3l-2 18" />
                                </svg>
                            </ButtonComponent>
                        </TooltipComponent>
                        <DividerComponent direction="vertical" />
                        <ButtonComponent type="info" @click="handleClose">
                            <span>Close</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path d="M18 6L6 18M6 6l12 12" />
                            </svg>
                        </ButtonComponent>
                    </RowComponent>
                </RowComponent>
            </RowComponent>
        </HeaderComponent>
        <ContainerComponent id="container">
            <MainComponent>
                <div :class="['preview-view', { 'is-mobile': isMobileDevice }]">
                    <DeviceComponent
                        :type="device"
                        :landscape="deviceLandscape"
                        v-if="isMobileDevice"
                    >
                        <ThemeComponent />
                    </DeviceComponent>
                </div>
            </MainComponent>
        </ContainerComponent>
    </ContainerComponent>
</template>

<script>
export default {
    name: 'Preview',

    metaInfo: {
        title: 'Preview'
    },

    data() {
        return {
            device: 'desktop',
            deviceLandscape: false,
            useNumbers: false
        }
    },

    computed: {
        isMobileDevice() {
            return this.device === 'tablet' || this.device === 'phone'
        }
    },

    methods: {
        handleDevice(device) {
            this.device = device
        },

        handleLandscape() {
            this.deviceLandscape = !this.deviceLandscape
        },

        handleNumbers() {
            this.useNumbers = !this.useNumbers
        },

        handleClose() {
            this.$router.push({ path: 'create' })
        }
    }
}
</script>

<style scope lang="scss">
.preview-view {
    display: flex;
    justify-content: center;
    align-items: center;

    height: auto;

    min-height: 100vh;
    max-height: 100vh;

    overflow-y: auto;

    &.is-mobile {
        background: radial-gradient(ellipse at center, #fff 0%, #eaeaea 100%);

        .theme-component {
            z-index: z-index('above');
        }

        .theme-component__background__overlay,
        .theme-component__background__inner {
            border-radius: 42px;
        }
    }

    .device-component {
        margin-bottom: 60px;
    }
}

.header-component__white {
    color: #2c3e50;

    padding: 0 20px;

    background-color: #fff;

    .row-component > .row-component > .row-component {
        .tooltip-component,
        .button-component {
            margin: 0 4px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .button-component {
        padding: 10px;

        &:hover {
            background-color: #ffd670 !important;
        }

        &.is-active {
            background-color: #ffba59 !important;
        }

        span {
            margin-top: 2px;
            margin-left: 4px;
            margin-right: 6px;
        }

        svg {
            transition: transform 0.2s ease;

            &.is-rotated {
                transform: rotate(-90deg);
            }
        }
    }
}
</style>
